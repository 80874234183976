import { api } from '../api';
import axios from 'axios';
import { apiData } from '../../Global/apiConstants';
import { defaultThemeColor } from '../../Global/constants';
import { mapToApplicationJsonBlob } from '../../Global/globalFunctions';

export const updateThemeAction = (color, logo, title, favicon, onSuccess) => {
  const themeData = {
    color: color.split('#')[1], // Removing the '#' from the color
    title: title
  };

  let formData = new FormData();
  formData.append('themeData', mapToApplicationJsonBlob(themeData));
  formData.append('favicon', favicon);
  formData.append('logo', logo);

  api
    .post('/config/theme', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(onSuccess);
};

export const getThemeAction = onSuccess => {
  const logo = axios.request({
    method: 'GET',
    url: `${apiData()}/public/theme`,
    responseType: 'blob',

    headers: {
      // Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
      'Content-Type': 'application/json'
    }
  });

  const color = axios.request({
    method: 'GET',
    url: `${apiData()}/public/theme/color`,
    responseType: 'json',

    headers: {
      // Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
      'Content-Type': 'application/json'
    }
  });

  const favicon = axios.request({
    method: 'GET',
    url: `${apiData()}/public/theme/favicon`,
    responseType: 'json',

    headers: {
      // Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
      'Content-Type': 'application/json'
    }
  });

  const title = axios.request({
    method: 'GET',
    url: `${apiData()}/public/theme/title`,
    responseType: 'json',

    headers: {
      // Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
      'Content-Type': 'application/json'
    }
  });

  Promise.all([logo, color, title, favicon])
    .then(values => {
      var url = undefined;
      if (values[0].data.size > 0) {
        var blob = new Blob([values[0].data], { type: 'image/png' });
        var url = URL.createObjectURL(blob);
      }

      var faviconUrl = undefined;
      if (values[3].data.size > 0) {
        var faviconBlob = new Blob([values[3].data], { type: 'image/png' });
        var faviconUrl = URL.createObjectURL(faviconBlob);
      }
      onSuccess({
        logo: url,
        color: values[1].data === '' ? defaultThemeColor : '#' + values[1].data,
        favicon: faviconUrl,
        title: values[2].data
      });
    })
    .catch(e => console.log(e));
};

export const getServerUrl = async () => {
  const data = await axios.get(`${apiData()}/public/serverUrl`);
  return data;
};

export const getDataByTargetAction = async target => {
  const { data } = await api.get(`/api/${target}`);
  return data;
};

export const getCsvByTargetAction = async target => {
  const { data } = await api.get(`/api/${target}`, {
    responseType: 'blob',
    headers: { 'Content-Type': 'text/csv', Accept: 'text/csv' }
  });
  return data;
};

export const getArrayBufferByTargetAction = async target => {
  const { data } = await api.get(`/api/${target}`, { responseType: 'arraybuffer' });
  return data;
};

export const updateItemByTargetAction = async (item, target, disableNotification) => {
  const { data } = await api.put(`/api/${target}/${item.id}`, item, {
    ...(!disableNotification && { notify: '' })
  });
  return data;
};

export const addItemByTargetAction = async (target, newData) => {
  const { data } = await api.post(`/api/${target}`, newData);
  return data;
};

export const addFileByTargetAction = async (target, newData) => {
  const { data } = await api.post(`/api/${target}`, newData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const deleteItemByTargetAndIdAction = async (target, itemId) => {
  const { data } = await api.delete(`/api/${target}/${itemId}`);
  return data;
};

export const customUpdateAction = async (url, dataToUpdate, disableNotification) => {
  const { data } = await api.put(`/api/${url}`, dataToUpdate, {
    ...(!disableNotification && { notify: '' })
  });
  return data;
};

export const customDeleteAction = async (url, deleteData) => {
  const { data } = await api.delete(`/api/${url}`, { data: deleteData });
  return data;
};

export const getPermissionsByObjectIdAndTarget = async (objectId, fileType) => {
  const { data } = await api.get(`/api/permissions/types`, { params: { objectId, fileType } });
  return data;
};

export const copyObjectAction = async (url, objectId) => {
  const { data } = await api.post(`/api/${url}/${objectId}`);
  return data;
};

export const getInfoAction = async () => {
  const { data } = await axios.request({
    method: 'GET',
    url: `${apiData()}/public/info`,
    responseType: 'json',

    headers: {
      // Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
      'Content-Type': 'application/json'
    }
  });
  return data;
};

export const validateRestorePasswordToken = async token => {
  const { data } = await axios.request({
    method: 'GET',
    url: `${apiData()}/public/passwordRecovery/${token}`,
    responseType: 'json',

    headers: {
      'Content-Type': 'application/json'
    }
  });

  return data;
};

// export const setInfoAction = async infoData => {
//   const { data } = await axios.request({
//     method: 'POST',
//     url: `${apiData()}/config/info`,
//     data: infoData,

//     headers: {
//       Authorization: 'Basic bWlhLWRtcy1ndWk6c2VjcmV0',
//       'Content-Type': 'application/json'
//     }
//   });
//   return data;
// };

export const setInfoAction = async infoData => {
  const { data } = await api.post('/config/info', infoData);
  return data;
};

export const exportTableAction = async (tableId, folderId) => {
  const { data } = await api.post(`/api/tables/${tableId}/generatePdf`, {
    folderId: folderId,
    timezoneOffset: new Date().getTimezoneOffset()
  });
  return data;
};
